import { View } from './view'
import { ViewMoneyForBusiness } from './viewMoneyForBusiness'
import { ViewNewLoanPromo } from './viewNewLoanPromo'
import { ViewNewLP } from './viewNewLP'
import { ViewWow } from './viewWow'

export const Footer = View
export const FooterNewLP = ViewNewLP
export const FooterNewLoanPromo = ViewNewLoanPromo
export const FooterWow = ViewWow
export const FooterMoneyForBusiness = ViewMoneyForBusiness
