import React from 'react'
import { Container } from '@shared/components/container'
import { FooterOwnProps } from '@shared/components/page-layout/model'

import { BaseFooter } from './templates/BaseFooter'

import styles from './style.module.scss'

export const View = ({
	enableNavItems,
	hideSocials,
	hideLoginAs,
	linkToDocuments,
	isNavsHidden,
	extraContent,
}: FooterOwnProps) => (
	<footer className={styles.wrapper}>
		<Container>
			<BaseFooter
				isNavsHidden={isNavsHidden}
				linkToDocuments={linkToDocuments}
				hideLoginAs={hideLoginAs}
				hideSocials={hideSocials}
				enableNavItems={enableNavItems}
				extraContent={extraContent}
			/>
		</Container>
	</footer>
)
