import React, { MouseEvent } from 'react'
import { Image } from '@shared/components/image'
import styled from 'styled-components'

export const Button = styled.button(({ theme }) => ({
	whiteSpace: 'nowrap',
	display: 'inline-block',
	color: '#fff',
	cursor: 'pointer',
	borderRadius: 8,
	backgroundColor: theme.colors.main,
	padding: '8px 20px',
	...theme.typography.text18_24,
	'&:hover': {
		backgroundColor: theme.colors.buttonhover,
	},
	'&:active': {
		backgroundColor: theme.colors.buttonpressed,
	},
	[theme.mediaQueries.mediaMinWidthMobile]: {
		padding: '16px 30px',
		...theme.typography.text24_32,
	},
}))

export const ButtonLink = styled.a(({ theme }) => ({
	whiteSpace: 'nowrap',
	display: 'inline-block',
	color: '#fff',
	cursor: 'pointer',
	borderRadius: 8,
	backgroundColor: theme.colors.main,
	padding: '8px 20px',
	...theme.typography.text18_24,
	'&:hover': {
		backgroundColor: theme.colors.buttonhover,
	},
	'&:active': {
		backgroundColor: theme.colors.buttonpressed,
	},
	[theme.mediaQueries.mediaMinWidthMobile]: {
		padding: '16px 30px',
		...theme.typography.text24_32,
	},
}))

interface ButtonTelegramProps {
	href?: string | undefined
	text?: string | undefined
	onClick?: (event: MouseEvent) => void
}
export const ButtonTelegram = ({ href, onClick, text }: ButtonTelegramProps): JSX.Element => (
	<ButtonTelegramStyled href={href} target='_blank' rel='noopener noreferrer' onClick={onClick}>
		<ImageTelegramStyled name='investTeleLogo.png' alt='' />
		{text || 'Обсудить в чате'}
	</ButtonTelegramStyled>
)

const ButtonTelegramStyled = styled(ButtonLink)(({ theme }) => ({
	borderWidth: 2,
	borderStyle: 'solid',
	borderColor: theme.colors.main,
	color: theme.colors.main,
	backgroundColor: 'white',
	flexDirection: 'row',
	alignItems: 'center',
	position: 'relative',
	'&:hover': {
		borderColor: theme.colors.buttonhover,
		color: theme.colors.buttonhover,
		backgroundColor: 'white',
	},
	'&:active': {
		borderColor: theme.colors.buttonpressed,
		color: theme.colors.buttonpressed,
		backgroundColor: 'white',
	},
	paddingLeft: 40,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		paddingLeft: 70,
	},
}))

const ImageTelegramStyled = styled(Image)(({ theme }) => ({
	width: 24,
	height: 24,
	position: 'absolute',
	left: 10,
	top: 8,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		left: 16,
		top: 12,
		width: 40,
		height: 40,
	},
}))
