import React from 'react'
import { Container } from '@shared/components/container'
import { CustomFooterOwnProps } from '@shared/components/page-layout/model'

import { WowFooter } from './templates/WowFooter'

import styles from './style.module.scss'

export const ViewWow = ({ extraContent, hideTopContent }: CustomFooterOwnProps) => (
	<footer className={styles.wrapper}>
		<Container>
			<WowFooter hideTopContent={hideTopContent} extraContent={extraContent} />
		</Container>
	</footer>
)
