import { MenuItem } from '@shared/menu-items/model'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'

export const footerItemsFirst: MenuItem[] = [
	{
		name: 'Кредиты',
		path: Paths.root,
		items: [
			{
				name: 'Деньги под залог недвижимости',
				path: Paths.productRealEstate,
			},
			{
				name: 'Квартира',
				path: Paths.productApartment,
			},
			{
				name: 'Карта сайта',
				path: Paths.sitemap,
			},
		],
	},
]

export const footerItemsSecond: MenuItem[] = [
	{
		name: 'Банкам',
		path: ExternalPaths.banks,
		items: [
			{
				name: 'Сбережения',
				path: Paths.invest,
			},
			{
				name: 'Партнёрам',
				path: Paths.partners,
			},
		],
	},
]

export const footerItemsThird: MenuItem[] = [
	{
		name: 'Журнал',
		path: ExternalPaths.companyJournal,
		items: [
			{
				path: Paths.about,
				name: 'О компании',
			},
			{
				name: 'Карьера',
				path: ExternalPaths.career,
			},
		],
	},
]

export const footerItemsFourth: MenuItem[] = [
	{
		name: 'Документы и раскрытие информации',
		path: Paths.documents,
		items: [
			{
				name: 'Проверка ПЭП',
				path: Paths.signature,
			},
			{
				name: 'Контакты',
				path: Paths.contacts,
			},
		],
	},
]
