import React from 'react'
import { Container } from '@shared/components/container'
import { CustomFooterOwnProps } from '@shared/components/page-layout/model'

import { FooterContentBlock } from './components/FooterContentBlock'
import { NewLPFooter } from './templates/NewLPFooter'
import { defaultLPFooterItems } from './constants'

import styles from './style.module.scss'

export const ViewNewLP = (props: CustomFooterOwnProps) => (
	<footer className={styles.wrapper}>
		<Container>
			<NewLPFooter FooterContentBlock={<FooterContentBlock items={defaultLPFooterItems} />} />
		</Container>
	</footer>
)
