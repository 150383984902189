import React, { PropsWithChildren } from 'react'
import { Container } from '@shared/components/container'
import cx from 'classnames'

import { SectionComponentProps } from './model'

import styles from './style.module.scss'

export const View = ({
	fullWidth,
	children,
	BackgroundSvg,
	className,
	contentClassName,
	...restHtmlProps
}: PropsWithChildren<SectionComponentProps>) => {
	return (
		<>
			{fullWidth ? (
				<section className={cx(styles.wrapper, className)} {...restHtmlProps}>
					{BackgroundSvg && BackgroundSvg}
					<Container className={cx(styles.content, contentClassName)}>{children}</Container>
				</section>
			) : (
				<Container className={className} tag='section' {...restHtmlProps}>
					{children}
				</Container>
			)}
		</>
	)
}
