import React from 'react'

import { LPFooterItems } from '../constants'

import styles from './style.module.scss'

interface Props {
	items: LPFooterItems[]
}

export const FooterContentBlock = ({ items }: Props) => {
	return (
		<div className={styles.text}>
			{items.map((item) => (
				<p key={item.key} className={styles.light}>
					{item.text}
				</p>
			))}
		</div>
	)
}
